import { memo, FC } from 'react';
import type { FaqQuestion } from '@framework/api/types';
import ContentWrapper from '@components/ContentWrapper';
import SectionWrapper from '@components/SectionWrapper';
import SectionHeading from '@components/SectionHeading';
import FAQS from './FAQS';
import * as styles from './styles';

type Props = {
  title?: string;
  titleSize?: 'small' | 'large';
  titleAlignment?: 'Center' | 'Left';
  faqQuestions?: FaqQuestion[];
  noWrapperSpacing?: boolean;
};

const FAQSection: FC<Props> = ({
  title,
  titleSize = 'large',
  titleAlignment,
  faqQuestions,
  noWrapperSpacing,
}) => (
  <ContentWrapper>
    <SectionWrapper noSpacing={noWrapperSpacing} data-test-id="faq-block">
      <div className={styles.container}>
        <div id="faq" className="scroll-mt-[12rem]">
          <SectionHeading
            textPosition={(titleAlignment ?? 'center').toLowerCase()}
            heading={title}
            headingSize={titleSize}
          />
        </div>
        <div className={styles.questionsWrapper}>
          <FAQS faqQuestions={faqQuestions} />
        </div>
      </div>
    </SectionWrapper>
  </ContentWrapper>
);

export default memo(FAQSection);
