import { classnames } from '@tailwindcss-classnames';

export const video = classnames('w-full', 'object-cover', 'object-center');
export const buttonContainer = classnames('mt-6');

const lightBackground = classnames(
  'bg-nzxt-light-grey-50',
  'text-nzxt-dark-grey-700'
);
const darkBackground = classnames('bg-nzxt-dark-grey-700', 'text-white');
const transparentBackground = classnames(
  'bg-transparent',
  'text-nzxt-dark-grey-700'
);
export const videoSection = (background?: string): string =>
  classnames('block', 'relative', 'overflow-hidden', 'w-full', {
    [lightBackground]: background === 'light',
    [darkBackground]: background === 'dark',
    [transparentBackground]: !background || background === 'transparent',
  });

export const videoContainer = classnames(
  'flex',
  'relative',
  'rounded-lg',
  'overflow-hidden'
);

export const thumbContainer = classnames(
  'rounded-lg',
  'relative',
  'bg-center',
  'bg-no-repeat',
  'bg-cover'
);

const activeThumb = classnames('bg-black', 'bg-opacity-50');
const inactiveThumb = classnames(
  'bg-gradient-to-t',
  'from-black',
  'to-transparent'
);

export const buttonOverlay = (isPlaying: boolean): string =>
  classnames('rounded-lg', 'absolute', 'top-0', {
    [activeThumb]: isPlaying,
    [inactiveThumb]: !isPlaying,
  });

export const thumb = classnames(
  'rounded-lg',
  'bg-center',
  'bg-no-repeat',
  'bg-cover',
  'relative'
);

export const nowPlaying = classnames('flex', 'items-center');
export const nowPlayingIcon = classnames('mr-1.5', 'w-3', 'h-3');

const baseVideos = classnames(
  'border-nzxt-volt-500',
  'overflow-x-auto',
  'flex',
  'pt-4'
);

const moreVideos = classnames(baseVideos, 'lg:justify-center');
const lessVideos = classnames(baseVideos, '2xl:justify-center');

export const videoThumbnailSection = (isManyVideos: boolean): string =>
  classnames({
    [lessVideos]: isManyVideos,
    [moreVideos]: !isManyVideos,
  });

export const verticalContainer = classnames('flex', 'flex-col');

export const thumbnailTitle = classnames(
  'text-sm',
  '2xl:text-base',
  'text-left',
  'truncate-2-liner'
);
export const thumbnailTextContainer = classnames(
  'text-white',
  'flex',
  'justify-between',
  'items-end',
  'h-full',
  'p-3',
  'relative'
);

const bgLight = classnames('text-nzxt-dark-grey-700', 'dark:text-white');
const bgDark = classnames('text-white');
export const title = (bgColor: string): string =>
  classnames(
    'text-lg',
    'md:text-xl',
    'lg:text-2xl',
    'font-bold',
    'dark:text-white',
    {
      [bgLight]: !bgColor || bgColor === 'light',
      [bgDark]: bgColor === 'dark',
    }
  );

export const detailsWrapper = classnames(
  'w-11/12',
  'flex',
  'items-center',
  'py-5',
  'lg:py-0',
  'lg:pt-7'
);

export const svgStyles = classnames(
  'h-16',
  'w-16',
  'md:h-28',
  'md:w-28',
  'text-nzxt-volt-500',
  'stroke-current'
);
const compactStyles = classnames('mx-auto', 'xl:px-20');
const defaultStyles = classnames('mt-6', 'lg:mt-10', 'lg:mb-9');

export const contentWrapper = (isCompact: boolean): string =>
  classnames(
    'lg:grid',
    'lg:grid-rows-1',
    'lg:grid-cols-4',
    'mx-auto',
    'auto-rows-min',
    'w-11/12',
    'lg:max-w-screen-lg',
    'xl:max-w-screen-xl',
    '2xl:max-w-screen-2xl',
    { [compactStyles]: isCompact, [defaultStyles]: !isCompact }
  );

const multipleVideos = classnames('lg:col-span-3', 'h-full');
const singleVideo = classnames('row-span-4', 'lg:col-span-4');
export const leftContainer = (hasMultipleVideos: boolean): string =>
  classnames('flex', 'flex-col', {
    [multipleVideos]: hasMultipleVideos,
    [singleVideo]: !hasMultipleVideos,
  });

const vertical = classnames('lg:overflow-y-auto', 'ml-6', 'pr-3');
const horizontal = classnames('w-screen');

export const videoButtonContainer = (
  background: string,
  isSmallVP: boolean
): string =>
  classnames('text-base', 'font-medium', {
    [bgLight]: background === 'light',
    [bgDark]: background === 'dark',
    [vertical]: !isSmallVP,
    [horizontal]: isSmallVP,
  });

export const viewport = classnames('relative');

export const buttonContainerBottom = classnames(
  'bg-gradient-to-t',
  'to-transparent',
  'h-12',
  'w-full',
  'sticky',
  'z-10'
);

export const playlistTitle = classnames('dark:text-white');
