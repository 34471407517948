import { classnames } from '@tailwindcss-classnames';

const darkText = classnames('text-nzxt-light-grey-800');
const defaultText = classnames('text-white');

export const eyebrow = (isInverted: boolean): string =>
  classnames('font-semibold', {
    [darkText]: isInverted,
    [defaultText]: !isInverted,
  });

export const heading = (isInverted: boolean): string =>
  classnames('h3', 'font-bold', 'uppercase', {
    [darkText]: isInverted,
    [defaultText]: !isInverted,
  });
export const subheading = (isInverted: boolean): string =>
  classnames('text-lg', 'mt-3', {
    [darkText]: isInverted,
    [defaultText]: !isInverted,
  });

export const buttonLabel = (isInverted: boolean): string =>
  classnames('mt-3', {
    [darkText]: isInverted,
    [defaultText]: !isInverted,
  });

const rounded = classnames('rounded-md');

export const video = (isRounded: boolean): string =>
  classnames('w-full', 'object-cover', 'object-center', {
    [rounded]: isRounded,
  });
export const buttonContainer = classnames('mt-6', 'min-w-max');

export const textContainer = classnames(
  'absolute',
  'transform',
  '-translate-x-1/2',
  '-translate-y-1/2',
  'left-1/2',
  'top-1/2',
  'text-center',
  'flex',
  'justify-center',
  'items-center',
  'flex-col',
  'w-screen'
);

export const videoSection = (isRounded: boolean): string =>
  classnames('relative', 'overflow-hidden', {
    [rounded]: isRounded,
  });

const darkPlay = classnames('text-nzxt-light-grey-700');
const lightPlay = classnames('text-nzxt-light-grey-300');
export const svgStyles = (isInverted: boolean): string =>
  classnames('h-10', 'w-10', 'stroke-current', {
    [darkPlay]: isInverted,
    [lightPlay]: !isInverted,
  });

const defaultPos = classnames('bottom-10', 'left-10');
const narrowPos = classnames(
  'left-10',
  'md:left-48',
  'xl:left-[96px]',
  'bottom-10'
);

export const buttonPosition = (isFullBleed: boolean): string =>
  classnames('absolute', {
    [defaultPos]: isFullBleed,
    [narrowPos]: !isFullBleed,
  });

const maxWidth = classnames('max-w-[2560px]', 'mx-auto');

export const contentWrapper = (isFullBleed: boolean): string =>
  classnames('relative', {
    [maxWidth]: isFullBleed,
  });
