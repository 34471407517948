import { classnames } from '@tailwindcss-classnames';

export const container = classnames('mx-auto');

export const title = classnames(
  'text-3xl',
  'font-extrabold',
  'text-gray-900',
  'text-center'
);

export const questionsWrapper = classnames('mt-12');

export const questionsDl = classnames('grid', 'grid-cols-faq', 'gap-8');

export const questionsDt = classnames(
  'md:text-xl',
  'text-nzxt-light-grey-800',
  'dark:text-nzxt-dark-grey-200',
  'text-lg',
  'leading-6',
  'font-semibold'
);

export const questionsDd = classnames(
  'mt-2',
  'text-base',
  'text-nzxt-light-grey-700',
  'dark:text-white'
);
