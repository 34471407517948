/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/media-has-caption */
import { FC, memo, useState, useRef, useEffect, useMemo } from 'react';
import cx from 'clsx';
import { useMedia, useTranslation as t } from '@utils/hooks';
import SectionWrapper from '@components/SectionWrapper';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import VideoButtons from './VideoButtons';
import * as styles from './styles';
import extraStyles from './extraStyles.module.css';

type UploadedVideo = {
  video: { mp4Url: string; thumbnailUrl: string; duration: number };
  title: string;
};

type YoutubeVideo = {
  videoId: string;
  title?: string;
  duration?: number;
};

export interface Props {
  id?: string | number;
  videos?: UploadedVideo[];
  youtubeVideos?: YoutubeVideo[];
  background?: string;
  isCompact?: boolean;
}

const VideoCarousel: FC<Props> = ({
  videos = [],
  youtubeVideos = [],
  background = 'transparent',
  isCompact = false,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const MORE_VIDEOS = t('label_more_videos');

  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const videoContainerRef = useRef<HTMLDivElement>();
  const buttonContainerRef = useRef<HTMLDivElement>();
  const uploadedVideos = videos.length > 0 ? videos : null;
  const ytVideos = youtubeVideos.length > 0 ? youtubeVideos : null;

  const allVideos = ytVideos || uploadedVideos;

  useEffect(() => {
    if (videos.length > 0)
      videoRefs.current = videoRefs.current.slice(0, videos.length);
    if (youtubeVideos.length > 0)
      videoRefs.current = videoRefs.current.slice(0, youtubeVideos.length);
  }, [videos, youtubeVideos]);

  const clickToSwitch = (index: number, identifer: HTMLVideoElement): void => {
    if (isPlaying) {
      setIsPlaying(false);

      if (typeof identifer !== 'string' && !ytVideos) {
        identifer?.pause();
      }
    }
    setActiveIndex(index);
  };

  const [videoHeight, setVideoHeight] = useState(null);

  useEffect(() => {
    const videoContainer = videoContainerRef.current;

    if (videoContainer) {
      const ro = new ResizeObserver(entries => {
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of entries) {
          const cr = entry.contentRect;
          setVideoHeight(() => cr.height);
        }
      });

      ro.observe(videoContainer);
    }
  }, []);

  useEffect(() => {
    if (allVideos?.length > 1) {
      if (isSm || isMd) buttonContainerRef.current.style.height = 'fit-content';
      else buttonContainerRef.current.style.height = `${videoHeight}px`;
    }
  }, [videoHeight, isSm, isMd, allVideos]);

  const composedVerticalWrapper = useMemo(
    () =>
      cx(
        styles.videoButtonContainer(background, isSm || isMd),
        extraStyles.verticalThumbnails
      ),
    [background, isSm, isMd]
  );

  return allVideos && Array.isArray(allVideos) && allVideos.length > 0 ? (
    <SectionWrapper noSpacing={isCompact}>
      <div className={styles.videoSection(background)}>
        <div className={styles.contentWrapper(isCompact)}>
          <div className={styles.leftContainer(allVideos?.length > 1)}>
            <div
              id="video-container"
              data-test-id="video-carousel-component"
              ref={videoContainerRef}
              className={styles.videoContainer}
              style={{ height: 'auto' }}
            >
              {uploadedVideos &&
                !ytVideos &&
                allVideos.map((video, i) => (
                  <video
                    ref={ref => (videoRefs.current[i] = ref)}
                    playsInline
                    data-test-id="upload-video-component"
                    src={video?.video?.mp4Url}
                    poster={video?.video?.thumbnailUrl}
                    key={video?.video?.mp4Url}
                    className={styles?.video}
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: activeIndex === i ? 'block' : 'none',
                    }}
                    controls
                    onClick={() => setIsPlaying(!isPlaying)}
                    onKeyDown={() => setIsPlaying(!isPlaying)}
                    tabIndex={0}
                    role="button"
                  />
                ))}

              {ytVideos &&
                allVideos.map(
                  (video, i) =>
                    i === activeIndex && (
                      <LiteYouTubeEmbed
                        key={video.videoId}
                        id={video.videoId}
                        adNetwork
                        params="showinfo=0&modestbranding=1&rel=0"
                        playlist={false}
                        poster="hqdefault"
                        title="YouTube Embed"
                        noCookie
                        playerClass={extraStyles.ltyPlaybtn}
                        wrapperClass={extraStyles.ytlite}
                        activatedClass={extraStyles.lytActivated}
                      />
                    )
                )}
            </div>

            {allVideos[activeIndex]?.title && (
              <div className={styles.detailsWrapper}>
                <h2
                  className={styles.title(background)}
                  data-test-id="video-component-title"
                >
                  {allVideos[activeIndex]?.title}
                </h2>
              </div>
            )}
          </div>
          {allVideos?.length > 1 && (
            <div className={composedVerticalWrapper} ref={buttonContainerRef}>
              <p
                className={styles.playlistTitle}
                data-test-id="more-video-title"
              >
                {MORE_VIDEOS}
              </p>
              <VideoButtons
                videos={allVideos}
                videoEl={videoRefs?.current[activeIndex]}
                dataTestId="more-video-component"
                activeIndex={activeIndex}
                switchHandler={clickToSwitch}
              />
              {!(isSm || isMd) && (
                <div
                  className={styles.buttonContainerBottom}
                  style={{
                    bottom: 0,
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </SectionWrapper>
  ) : null;
};

export default memo(VideoCarousel);
