/* eslint-disable no-underscore-dangle */
import { FC, memo, useRef, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import cn from 'clsx';
import type { DatoProductImage, LinkedItem } from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import useIntersectionObserver from '@utils/hooks/useIntersectionObserver';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import PlayPause from '@components/VideoCarousel/PlayPause/PlayPause';
import ButtonLink from '@components/ButtonLink';
import extraStyles from './extraStyles.module.css';
import * as styles from './styles';

export interface Props {
  id?: string;
  video: { video: { mp4Url: string } };
  videoPoster: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  darkText?: boolean;
  buttonLabel?: string;
  buttonLink?: LinkedItem;
  noWrapperSpacing?: boolean;
  rounded?: boolean;
  variableSpacing?: string;
  isFullBleed?: boolean;
  internalButtonLink?: LinkedItem;
}

const VideoBlock: FC<Props> = ({
  eyebrow,
  heading,
  subheading,
  video,
  videoPoster,
  buttonLabel,
  buttonLink,
  darkText,
  noWrapperSpacing = false,
  rounded = false,
  variableSpacing,
  isFullBleed = true,
}) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const observedEl = useIntersectionObserver(ref, {});
  const isVisible = !!observedEl?.isIntersecting;
  const [isPlaying, setIsPlaying] = useState(true);
  const [manuallyPaused, setManuallyPaused] = useState(false);

  const clickToPlay = (target: HTMLVideoElement): void => {
    const playPromise = target?.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          setIsPlaying(true);
          if (isPlaying) {
            setManuallyPaused(true);
            setIsPlaying(false);
            target?.pause();
          }
        })
        .catch(error => {
          throw error;
          Sentry.captureException(error);
        });
    }
  };

  useEffect(() => {
    if (ref?.current) {
      if (!manuallyPaused) {
        const playPromise = ref.current.play();

        if (!isVisible) {
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                if (!isVisible && isPlaying) {
                  ref.current.pause();
                }
              })
              .catch(err => {
                Sentry.captureException(err);
                throw err;
              });
          }
        }
      }
    }
  }, [isVisible]);

  const internalHref = buttonLink
    ? getCmsLinkUrl({
        contentType: buttonLink._modelApiKey,
        pageType: buttonLink.pageType,
        slug: buttonLink.slug,
        parentSlug: buttonLink?.parentCategory?.slug,
      })
    : null;

  return video?.video?.mp4Url && videoPoster?.responsiveImage?.src ? (
    <SectionWrapper
      noSpacing={noWrapperSpacing}
      className={styles.videoSection(rounded)}
      data-test-id="video-block"
      variableSpacing={variableSpacing}
    >
      <ContentWrapper
        fullBleed={isFullBleed}
        className={styles.contentWrapper(isFullBleed)}
        dataTestId="video-block-content-wrapper"
      >
        <video
          ref={ref}
          loop
          muted
          playsInline
          src={video.video.mp4Url}
          poster={videoPoster?.responsiveImage.src}
          className={styles.video(rounded || !isFullBleed)}
          style={{ minHeight: '400px', maxHeight: '700px', zIndex: -1 }}
          data-test-id="video-block-video-play"
        />
        <button
          onClick={() => clickToPlay(ref?.current)}
          type="button"
          className={styles.buttonPosition(isFullBleed)}
        >
          <PlayPause
            isPlaying={isPlaying}
            svgStyles={styles.svgStyles(darkText)}
          />
        </button>

        {(heading || (internalHref && buttonLabel)) && (
          <div className={styles.textContainer}>
            {eyebrow && (
              <p
                className={cn(styles.eyebrow(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-eyebrow"
              >
                {eyebrow}
              </p>
            )}
            {heading && (
              <p
                className={cn(styles.heading(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-heading"
              >
                {heading}
              </p>
            )}
            {subheading && (
              <p
                className={cn(styles.subheading(darkText), {
                  [extraStyles.glowLight]: !darkText,
                  [extraStyles.glowDark]: darkText,
                })}
                data-test-id="video-block-subheading"
              >
                {subheading}
              </p>
            )}
            {internalHref && buttonLabel && (
              <ButtonLink
                buttonStyle="primary"
                dark={!darkText}
                href={internalHref}
                internalLink
                className={styles.buttonContainer}
                dataTestId="video-block-button"
              >
                <span
                  className={cn({
                    [extraStyles.glowLight]: !darkText,
                    [extraStyles.glowDark]: darkText,
                  })}
                  data-test-id="video-block-button-label"
                >
                  {buttonLabel}
                </span>
              </ButtonLink>
            )}
          </div>
        )}
      </ContentWrapper>
    </SectionWrapper>
  ) : null;
};

export default memo(VideoBlock);
