import { classnames } from '@tailwindcss-classnames';

export const container = classnames(
  'grid',
  'gap-4',
  'grid-cols-1',
  'md:grid-cols-1'
);

export const item = classnames(
  'border',
  'border-gray-300',
  'dark:border-nzxt-dark-grey-400',
  'p-4',
  'rounded-md',
  'w-full'
);

export const title = classnames(
  'text-nzxt-light-grey-800',
  'dark:text-white',
  'text-base',
  'sm:text-lg',
  'md:text-xl',
  'leading-6',
  'font-semibold'
);

export const content = classnames(
  'mt-2',
  'text-base',
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-100'
);

const activeAction = classnames('rotate-180');

export const svg = (isActive: boolean): string =>
  classnames(
    {
      [activeAction]: isActive,
    },
    'w-6',
    'h-6',
    'stroke-current',
    'text-gray-900',
    'transition-transform',
    'duration-300',
    'transform',
    'pl-0.5'
  );

export const accordionButton = classnames(
  'text-left',
  'flex',
  'justify-between',
  'items-center',
  'w-full'
);
