import { memo, FC } from 'react';
import type { FaqQuestion } from '@framework/api/types';
import Accordion from '@components/Accordion/Accordion';

type Props = {
  faqQuestions?: FaqQuestion[];
};

const Faqs: FC<Props> = ({ faqQuestions }) => (
  <Accordion
    items={faqQuestions.map(question => ({
      id: question.id,
      title: question.question,
      content: question.answer,
      markdownContent: question.markdownContent,
    }))}
  />
);

export default memo(Faqs);
