import { memo, FC, useState, useRef, useEffect } from 'react';
import { IconChevronDown } from '@nzxt/react-icons';
import HydrateMdx from '@components/HydrateMdx';
import { MDXRemoteSerializeResult } from 'next-mdx-remote';
import * as styles from './styles';

type AccordionItem = {
  id: string;
  title: string;
  content: string;
  markdownContent?: MDXRemoteSerializeResult;
};

type AccordionItemProps = {
  title: string;
  content: string;
  markdownContent?: MDXRemoteSerializeResult;
  active: boolean;
  onToggle: () => void;
};

type AccordionProps = {
  items: AccordionItem[];
  single?: boolean;
};

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  content,
  markdownContent,
  active,
  onToggle,
}) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (active && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [active, contentRef.current?.scrollHeight]);

  const dynamicStyles = {
    height: active ? `${contentHeight}px` : '0px',
    overflow: 'hidden',
    transition: 'height 200ms ease-in-out',
  };

  return (
    <div className={styles.item}>
      <button
        type="button"
        className={styles.accordionButton}
        onClick={onToggle}
      >
        <dt className={styles.title}>{title}</dt>

        <div>
          <IconChevronDown className={styles.svg(active)} />
        </div>
      </button>
      <div ref={contentRef} style={dynamicStyles}>
        <dd
          className={`${styles.content} ${
            markdownContent ? 'prose dark:prose-invert' : ''
          }`}
        >
          {markdownContent ? (
            <HydrateMdx mdx={markdownContent} />
          ) : (
            <div>{content}</div>
          )}
        </dd>
      </div>
    </div>
  );
};

const Accordion: FC<AccordionProps> = ({ single = true, items }) => {
  const [activeStates, setActiveStates] = useState<Array<boolean>>(
    new Array(items.length).fill(false)
  );

  const toggleAccordion = (index: number): void => {
    setActiveStates(currentStates => {
      if (!single) {
        return currentStates.map((active, i) =>
          i === index ? !active : active
        );
      }

      return currentStates.map((active, i) => (i === index ? !active : false));
    });
  };

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <AccordionItem
          key={item.id}
          title={item.title}
          content={item.content}
          markdownContent={item.markdownContent}
          active={activeStates[index]}
          onToggle={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default memo(Accordion);
